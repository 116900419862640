import { MathService } from './../../services/math-service';
import { ServiceReportService } from './../../services/service-report-service';
import { ServiceStationService } from 'services/service-station-service';
import { ErrorService } from 'services/error-service';
import { UserService } from 'services/user-service';
import { autoinject, observable } from 'aurelia-framework';
import * as moment from 'moment';
import { Router } from 'aurelia-router';
import $ from 'jquery';

@autoinject
export class ServiceKpis {
  private serviceStationId: number;
  selectedServiceStationIds: string[] = [];
  private year: number;

  private exportServiceKpiProgress = false;

  private kpiData: any;
  private isLoading = false;
  private serviceStations: Array<any>;

  private years: Array<any> = [];
  private months: Array<any> = [];

  @observable()
  withNewNetAntifouling = false;
  withNewNetAntifoulingChanged(_, prev?: boolean) {
    if (prev === undefined) return;
    this.updateKpis();
  }

  constructor(
    private serviceReportService: ServiceReportService,
    private userService: UserService,
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private mathService: MathService,
    private router: Router
  ) {
    const currentYear = moment.default().year();
    let startYear = 2020;
    while (startYear <= currentYear + 1) {
      const thisYear = startYear++;
      this.years.push({ Year: thisYear, YearEn: thisYear, YearEs: thisYear });
    }

    for (let i = 0; i < 12; i++) {
      this.months.push({ Month: i, Name: moment.default().month(i).format('MMM') });
    }

    this.getServiceStations();
    this.userService.getCurrentUser().then((user) => {
      // build default filters for statistics
      this.serviceStationId = user.ServiceStationId;
      if (this.serviceStationId) {
        this.selectedServiceStationIds.push(this.serviceStationId.toString());
      }

      this.year = moment.default().year();

      this.getKpis();
    });
  }

  getServiceStations() {
    return this.serviceStationService.getAllCached().then((res) => {
      res = res.filter((x) => !x.IsDeleted);

      this.serviceStations = res;
    });
  }

  setServiceStation(values) {
    if (JSON.stringify(this.selectedServiceStationIds) != JSON.stringify(this.getServiceStationIds(values))) {
      this.selectedServiceStationIds = this.getServiceStationIds(values);
      this.updateKpis();
    }
  }

  setYear(year) {
    if (this.year != +year) {
      this.year = +year;
      this.updateKpis();
    }
  }

  updateKpis() {
    this.getKpis();
  }

  async getKpis() {
    try {
      this.isLoading = true;
      const res = await this.serviceReportService.getServiceKpiResults(
        this.selectedServiceStationIds,
        this.year,
        false,
        this.withNewNetAntifouling
      );
      const kpiData = res;

      const aggregatedKpiData = {
        Washed: [],
        Service: [],
        Antifouling: [],
      };

      kpiData.forEach((kpi) => {
        if (kpi.Month == 0) {
          // No kpi is configured
          // Code for displaying message or logging
          // can be added here...
        } else {
          let washKpi = aggregatedKpiData.Washed.find((x) => x.ServiceStationId == kpi.ServiceStationId);

          if (!washKpi) {
            washKpi = this.getDefaultMonthKpi(kpi);
            aggregatedKpiData.Washed.push(washKpi);
          }

          const washWeek = this.getWeekDataKpi(kpi);
          washKpi.MonthData[kpi.Month - 1].WeekData.push(washWeek);
          washKpi.MonthData[kpi.Month - 1].WorkDays += kpi.WorkDays;
          washKpi.MonthData[kpi.Month - 1].Target = kpi.TargetWashing;
          washKpi.MonthData[kpi.Month - 1].Result += kpi.ActualWashing;
          washWeek.Target = kpi.TargetWashing;
          washWeek.Result = kpi.ActualWashing;

          this.setColorCode(washKpi.MonthData[kpi.Month - 1]);

          let repairKpi = aggregatedKpiData.Service.find((x) => x.ServiceStationId == kpi.ServiceStationId);

          if (!repairKpi) {
            repairKpi = this.getDefaultMonthKpi(kpi);
            aggregatedKpiData.Service.push(repairKpi);
          }

          const repairWeek = this.getWeekDataKpi(kpi);
          repairKpi.MonthData[kpi.Month - 1].WeekData.push(repairWeek);

          repairKpi.MonthData[kpi.Month - 1].WorkDays += kpi.WorkDays;
          repairKpi.MonthData[kpi.Month - 1].Target = kpi.TargetHours;
          repairKpi.MonthData[kpi.Month - 1].Result += kpi.ActualHours;
          repairWeek.Target = kpi.TargetHours;
          repairWeek.Result = kpi.ActualHours;

          this.setColorCode(repairKpi.MonthData[kpi.Month - 1]);

          let antifoulingKpi = aggregatedKpiData.Antifouling.find((x) => x.ServiceStationId == kpi.ServiceStationId);

          if (!antifoulingKpi) {
            antifoulingKpi = this.getDefaultMonthKpi(kpi);
            aggregatedKpiData.Antifouling.push(antifoulingKpi);
          }

          const antifoulingWeek = this.getWeekDataKpi(kpi);
          antifoulingKpi.MonthData[kpi.Month - 1].WeekData.push(antifoulingWeek);

          antifoulingKpi.MonthData[kpi.Month - 1].WorkDays += kpi.WorkDays;
          antifoulingKpi.MonthData[kpi.Month - 1].Target = kpi.TargetAntifoulingLiters;
          antifoulingKpi.MonthData[kpi.Month - 1].Result += kpi.ActualAntifoulingLiters;
          antifoulingWeek.Target = kpi.TargetAntifoulingLiters;
          antifoulingWeek.Result = kpi.ActualAntifoulingLiters;

          this.setColorCode(antifoulingKpi.MonthData[kpi.Month - 1]);
        }
      });

      // the budgets are per month, divide the budget on each week in the month
      // based on workdays
      this.calculateWeekBudgets(aggregatedKpiData.Washed);
      this.calculateWeekBudgets(aggregatedKpiData.Service);
      this.calculateWeekBudgets(aggregatedKpiData.Antifouling);

      // calculate YTD and Sum for each KPI
      this.calculateSums(aggregatedKpiData.Washed);
      this.calculateSums(aggregatedKpiData.Service);
      this.calculateSums(aggregatedKpiData.Antifouling);

      // setup an array to simplify the template handling, and add a total row for each
      // group if there are more than one service station in each group
      const arrayKpiData = [
        { no: 1, data: this.addTotalRowIfMultiple(aggregatedKpiData.Washed) },
        { no: 2, data: this.addTotalRowIfMultiple(aggregatedKpiData.Service) },
        { no: 3, data: this.addTotalRowIfMultiple(aggregatedKpiData.Antifouling) },
      ];

      this.kpiData = arrayKpiData;
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isLoading = false;
  }

  private setColorCode(monthData) {
    const currentWeek = moment.default().week();
    const currentYear = moment.default().year();

    const setStyle = !monthData?.WeekData
      ? true
      : (monthData.WeekData as { Week: number; Year: number }[])?.some(
          (week) => week.Week <= currentWeek || currentYear > this.year
        );

    if (monthData.Target) {
      if (monthData.Result >= monthData.Target && setStyle) {
        monthData.Style = 'result-good';
      } else {
        const diff = monthData.Result - monthData.Target;

        if (Math.abs(diff / monthData.Target) < 0.2 && setStyle) {
          monthData.Style = 'result-medium';
        } else if (setStyle) {
          monthData.Style = 'result-bad';
        }
      }
    }
  }

  private calculateWeekBudgets(data: Array<any>) {
    data.forEach((ss) => {
      if (ss.MonthData) {
        ss.MonthData.forEach((m) => {
          if (m.WeekData) {
            m.WeekData.forEach((w) => {
              const workDaysPercentage = w.WorkDays / m.WorkDays;
              const weekBudget = w.Target * workDaysPercentage;
              w.Target = this.mathService.round(weekBudget, 1);
            });
          }
        });
      }
    });
  }

  private calculateSums(data: Array<any>) {
    const currentWeek = moment.default().week();
    const currentYear = moment.default().year();

    data.forEach((ss) => {
      if (ss.MonthData) {
        ss.MonthData.forEach((m) => {
          ss.Sum.Result += m.Result;
          ss.Sum.Target += m.Target;

          if (m.WeekData) {
            m.WeekData.forEach((w) => {
              if (this.year < currentYear || (this.year == currentYear && w.Week <= currentWeek)) {
                ss.Ytd.Target += w.Target;
                ss.Ytd.Result += w.Result;
              }
            });
          }
        });
      }

      ss.Sum.Result = this.mathService.round(ss.Sum.Result, 1);
      ss.Sum.Target = this.mathService.round(ss.Sum.Target, 1);
      ss.Ytd.Result = this.mathService.round(ss.Ytd.Result, 1);
      ss.Ytd.Target = this.mathService.round(ss.Ytd.Target, 1);

      this.setColorCode(ss.Ytd);
      if (this.year < currentYear) {
        this.setColorCode(ss.Sum);
      }
    });
  }

  private addTotalRowIfMultiple(data) {
    if (data.length <= 1) {
      return data;
    }

    const sumRow = {
      ServiceStationName: 'TOTAL',
      IsTotalRow: true,
      MonthData: [],
      Sum: {
        Result: 0,
        Target: 0,
      },
      Ytd: {
        Result: 0,
        Target: 0,
      }
    };

    for (let i = 0; i < 12; i++) {
      sumRow.MonthData.push({
        Target: 0,
        Result: 0,
        WeekData: [],
      });

      for (let j = 0; j < data[0].MonthData[i].WeekData.length; j++) {
        sumRow.MonthData[i].WeekData.push({
          Target: 0,
          Result: 0,
        });
      }

      for (let j = 0; j < data.length; j++) {
        if (data[j].NoKpiConfigured === false) {
          sumRow.MonthData[i].Target += data[j].MonthData[i].Target;
          sumRow.MonthData[i].Result += data[j].MonthData[i].Result;

          for (let k = 0; k < data[j].MonthData[i].WeekData.length; k++) {
            sumRow.MonthData[i].WeekData[k].Target += +data[j].MonthData[i].WeekData[k].Target;
            sumRow.MonthData[i].WeekData[k].Result += +data[j].MonthData[i].WeekData[k].Result;
          }
        }
      }
    }

    for (let j = 0; j < sumRow.MonthData.length; j++) {
      const currentYear = moment.default().year()

      sumRow.Sum.Result += sumRow.MonthData[j].Result
      sumRow.Sum.Target += sumRow.MonthData[j].Target

      sumRow.MonthData[j].WeekData.forEach(element => {
        sumRow.Ytd.Result += element.Result;
        sumRow.Ytd.Target += element.Target;
      });

      sumRow.Sum.Result = this.mathService.round(sumRow.Sum.Result, 1);
      sumRow.Sum.Target = this.mathService.round(sumRow.Sum.Target, 1);
      sumRow.Ytd.Result = this.mathService.round(sumRow.Ytd.Result, 1);
      sumRow.Ytd.Target = this.mathService.round(sumRow.Ytd.Target, 1);

      this.setColorCode(sumRow.Ytd)
      if(this.year < currentYear) {
        this.setColorCode(sumRow.Sum);
      }
    }

    for (let i = 0; i < 12; i++) {
      this.setColorCode(sumRow.MonthData[i]);
    }

    data.push(sumRow);

    return data;
  }

  private getWeekDataKpi(kpi) {
    const weekKpi = {
      Week: kpi.Week,
      Target: 0,
      Result: 0,
      WorkDays: kpi.WorkDays,
    };

    return weekKpi;
  }

  private getDefaultMonthKpi(kpi) {
    const monthKpi = {
      ServiceStationId: kpi.ServiceStationId,
      ServiceStationName: kpi.ServiceStationName,
      NoKpiConfigured: false,
      MonthData: [],
      Sum: { Result: 0, Target: 0 },
      Ytd: { Result: 0, Target: 0 },
    };

    for (let i = 0; i < 12; i++) {
      monthKpi.MonthData.push({
        Target: 0,
        Result: 0,
        WorkDays: 0,
        WeekData: [],
      });
    }

    return monthKpi;
  }

  private toggleMonth(month) {
    if (!month._weeks) {
      let weeks = null;

      if (this.kpiData[0].data.length > 0) {
        for (let i = 0; i < this.kpiData[0].data.length; i++) {
          if (this.kpiData[0].data[i].MonthData.length > 0) {
            weeks = this.kpiData[0].data[i].MonthData[month.Month].WeekData.map((x) => x.Week);
            break;
          }
        }
      }

      month._weeks = weeks;
    }

    month._expanded = !month._expanded;
  }

  exportServiceKpis() {
    this.exportServiceKpiProgress = true;

    this.serviceReportService
      .getServiceKpiResults(this.selectedServiceStationIds, this.year, true, this.withNewNetAntifouling)
      .then((res) => {
        // ignore result, service handles export
        this.exportServiceKpiProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.exportServiceKpiProgress = false;
      });
  }

  private getServiceStationIds(selectedValues): string[] {
    if (!selectedValues) return [];
    const serviceStationIds = selectedValues.map((v) => v.id);
    return serviceStationIds;
  }
}
